import React from 'react'
import Layout from '../components/layout'
import Activities from '../components/activities'
import CallToAction from '../components/call-to-action'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Text, Title, Container } from '../elements'

import { Box } from '../elements'

import { H3, Paragraph } from '../elements/typography'

const UnorderedList = styled.ul`
  margin-bottom: 2rem;
`

const ListItem = styled.li`
  margin: 0;
  list-style: none;
  position: relative;
  padding-left: 20px;
  font-size: 1rem;
  color: var(--color-secondary);

  @media (min-width: 768px) {
    font-size: 1.125rem;
  }

  :before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    background: var(--accent-color-blue);
    border-radius: 10px;
    width: 6px;
    height: 6px;
    left: 1px;
    top: 10px;
  }
`
const AboutPage = ({ data }) => {
  return (
    <Layout>
      <section
        id="about"
        css={`
          background: var(--background-color-primary);
          color: var(--color-primary);
          // font-family: 'Proxima Nova';
          // font-weight: 400;
          padding-top: 2rem;
          @media screen and (min-width: 550px) {
            padding-top: 5rem;
          }
        `}
      >
        <Container mt="5rem">
          <Box mb={9} mx="auto" className="max-w-2xl">
            <Title as="h1">
              Hoi, ik ben Remco
              <span role="img" aria-label="Wave hand" className="ml-2">
                👋
              </span>
            </Title>
            <Text fontSize={[4, null, 5]} lineHeight={3} mb={6}>
              Mijn naam is Remco Zwartepoorte: eigenaar Absolute Code &
              self-taught web developer. Ik ben als front-end web developer
              gespecialiseerd in onder andere <strong>HTML</strong>,{' '}
              <strong>CSS</strong> en <strong>JavaScript</strong>.
            </Text>
            <Text>
              Al vanaf mijn jeugd ben ik bezig met internet, code en techniek.
              Het plezier dat ik eruit haal, deel ik graag met leuke
              opdrachtgevers. Samen mooie dingen ontwerpen en maken, dat wil ik.
              Mezelf blijven ontwikkelen en niet bang zijn iets nieuws te
              proberen.
            </Text>
            <Text mb={6}>
              Ik kijk naar designs door een user experience bril en knip deze op
              in herbruikbare componenten. Data-uitwisseling tussen API's en
              front-end applicaties houd ik eenvoudig, efficiënt en flexibel
              dankzij het gebruik van GraphQL.
            </Text>
            <Title as="h3" fontSize={[5, null, 6]} mb={4}>
              Ik vind het gaaf om te werken met
            </Title>
            <UnorderedList>
              <ListItem>React / Gatsby</ListItem>
              <ListItem>Design Systems</ListItem>
              <ListItem>Cloud Functions</ListItem>
              <ListItem>Apollo GraphQL</ListItem>
              <ListItem>Styled Components</ListItem>
            </UnorderedList>
            <Title as="h2" fontSize={[5, null, 6]} mb={4}>
              Ik heb ervaring met
            </Title>
            <UnorderedList>
              <ListItem>ontwerpen en bouwen van (nieuwe) websites</ListItem>
              <ListItem>
                moderne, solide HTML, CSS (of Sass, of Styled Components) en
                JavaScript (vanilla of met React)
              </ListItem>
              <ListItem>
                front-end tooling om HTML, CSS en JavaScript te optimaliseren,
                linten of samenvoegen
              </ListItem>
              <ListItem>
                responsive design en daaraan gerelateerde best practices
              </ListItem>
              <ListItem>web fonts en webtypografie</ListItem>
              <ListItem>
                samenwerken met SCRUM, git, svn en andere tools
              </ListItem>
            </UnorderedList>
            <Paragraph>
              Zoek je een front-end developer met actuele kennis van moderne
              tools?{' '}
              <Link
                to="/contact"
                className="font-bold underline-indigo hover:underline"
                style={{
                  color: 'var(--color-primary)',
                }}
              >
                Neem gerust contact op!
              </Link>
            </Paragraph>
          </Box>
        </Container>
      </section>

      
      <CallToAction></CallToAction>
    </Layout>
  )
}

export default AboutPage

export const query = graphql`
  query {
    latestImage: file(relativePath: { eq: "screenshot.png" }) {
      childImageSharp {
        fluid(maxWidth: 1100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
