import React from 'react'
import styled from 'styled-components'
import { Container } from '../elements'

import Activity from './activity'
import FollowMeOnStrava from './follow-me'

const StyledActivities = styled.section`
  --activities-accent-color: ${props => props.theme.colors.orange[5]};
  --activities-background-color: ${props => props.theme.colors.gray[0]};
  --activities-card-background-color: ${props => props.theme.colors.white};

  @media (prefers-color-scheme: dark) {
    --activities-accent-color: ${props => props.theme.colors.orange[5]};
    --activities-background-color: ${props => props.theme.colors.gray[7]};
    --activities-card-background-color: ${props => props.theme.colors.gray[6]};
  }

  background-color: var(--activities-background-color);
`

const ActivityGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  gap: 20px;

  @media screen and (min-width: 640px) {
    grid-template-columns: repeat(auto-fill, minmax(24rem, 1fr));
  }
`

const Activities = ({ activities }) => (
  <StyledActivities className="py-24 md:py-32 xl:py-40">
    <Container>
      <div className="mb-12">
        <p
          css={`
            color: var(--color-primary);
          `}
          className="font-bold text-4xl sm:text-5xl md:text-6xl tracking-tight leading-none mb-4"
        >
          Stay happy and healthy
        </p>
        <p
          css={`
            color: var(--color-secondary);
          `}
          className="text-base sm:text-lg xl:text-xl max-w-3xl"
        >
          Om zowel mentaal als fysiek in topconditie te blijven fiets ik graag.
          Als de tijd en energie het toelaten ook langere ritten van 3 à 4 uur.
          Zowel op mountainbike als racefiets. Dit zijn mijn zes meest recente
          fietsritten die ik heb vastgelegd op Strava.
        </p>
      </div>

      <ActivityGrid>
        {activities &&
          activities.map(edge => {
            const { id, start_date, name } = edge.node.activity
            // const name = `${edge.node.activity.name.substr(0, 22)}...`
            const hours = Math.floor(edge.node.activity.moving_time / 60 / 60)
            const minutes = Math.floor(edge.node.activity.moving_time / 60) % 60
            const distance = (edge.node.activity.distance / 1000).toFixed(2)
            const elevation = edge.node.activity.total_elevation_gain.toFixed(0)
            const racefiets =
              edge.node.activity.gear_id === 'b1084143' ? true : null
            const mountainbike =
              edge.node.activity.gear_id === 'b2549377' ? true : null
            return (
              <Activity
                key={id}
                id={id}
                start_date={start_date}
                name={name}
                hours={hours}
                minutes={minutes}
                distance={distance}
                elevation={elevation}
                racefiets={racefiets}
                mountainbike={mountainbike}
              />
            )
          })}
      </ActivityGrid>
      <div className="text-center mt-12">
        <FollowMeOnStrava />
      </div>
    </Container>
  </StyledActivities>
)

export default Activities
