import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Container } from '../elements'
import { FiArrowRight } from 'react-icons/fi'

import { H2, Paragraph } from '../elements/typography'

const Content = styled.div`
  text-align: center;
  padding: 8rem 0;
`

const CallToAction = () => {
  return (
    <Container>
      <Content>
        <H2>Wil je graag samenwerken?</H2>
        <Paragraph
          css={`
            color: var(--color-secondary);
          `}
          className="mx-auto max-w-xl"
        >
          Ik woon in Amersfoort, maar werk samen met mensen en bedrijven overal.
          Ik reis binnen Nederland tot ~1 uur, maar werk ook op afstand.
        </Paragraph>

        <Link
          to="/contact"
          className="w-full sm:w-auto font-semibold text-base sm:text-xl py-3 px-4 my-4 lg:my-6 rounded border leading-tight"
          css={`
            transition: 300ms cubic-bezier(0.4, 0, 0.6, 1);
            background-color: var(--accent-color-blue);
            border-color: var(--accent-color-blue);
            color: ${props => props.theme.colors.white};
            display: inline-flex;
            align-items: center;
          `}
        >
          {' '}
          Neem contact op
          <FiArrowRight
            css={`
              margin-left: 0.2em;
            `}
          ></FiArrowRight>
        </Link>
      </Content>
    </Container>
  )
}

export default CallToAction
